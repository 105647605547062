import React from 'react';
import './ToggleSwitch.css';

interface ToggleSwitchProps {
  id: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  name?: string;
  label?: string;
  text?: string;
  optionLabels?: [string, string];
  small?: boolean;
  disabled?: boolean;
  errorMsg?: string;
  className?: string;
  optional?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  id,
  name,
  label,
  text,
  checked,
  className,
  optional = false,
  errorMsg,
  onChange,
  optionLabels = ['Yes', 'No'],
  small = false,
  disabled = false,
}) => (
  <div className={`flex flex-col ${className}`}>
    <div className="w-full flex justify-between items-baseline">
      <label
        htmlFor={id}
        className={`text-xs uppercase
    mb-1 ml-4 mt-2 ${disabled && ' text-blue-alertText'} text-blue `}
      >
        {label}
      </label>
      {optional && <span className="text-blue italic text-xs">optional</span>}
    </div>

    <div className={`toggle-switch${small ? ' small-switch' : ''}`}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      {id && (
        <label
          htmlFor={id}
          className="toggle-switch-label"
          aria-label={`Toggle switch ${
            checked ? optionLabels[0] : optionLabels[1]
          }`}
        >
          {text}
          <span
            className={
              disabled
                ? 'toggle-switch-inner toggle-switch-disabled'
                : 'toggle-switch-inner'
            }
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
            tabIndex={-1}
          />
          <span
            className={
              disabled
                ? 'toggle-switch-switch toggle-switch-disabled'
                : 'toggle-switch-switch'
            }
            tabIndex={-1}
          />
        </label>
      )}
    </div>

    <p className=" text-xs font-light text-red mt-1 ml-4">{errorMsg}</p>
  </div>
);

export default ToggleSwitch;
