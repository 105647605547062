import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  Modal,
  FormLayout,
  Button,
  InputText,
  Select,
  ToogleComponent,
} from '../../../../components';
import School, { IStudent } from '../../local-helpers/requests';
import { ISelectedValue } from '../../../../components/Select';
import { limitTo16Years } from '../../../../helpers/functions-and-objects';
import { regPhone } from '../../../../helpers/regex';
import { useCurrentUser } from '../../../../contexts/UserContext';

type Props = {
  isStudentCreated: boolean,
  onClose: ()=> void,
  students: IStudent[],
  campuses: ISelectedValue[],
  departments: ISelectedValue[],
  isGasabo : boolean
}

const CreateStudent: React.FC<Props> = ({
  isStudentCreated, onClose, students, campuses, departments, isGasabo,
}: Props) => {
  const { user } = useCurrentUser();
  const API = new School();
  const {
    mutate, isLoading,
  } = useMutation((payload: IStudent) => API.createStudent(payload, user?.schools[0]?.id));

  const queryClient = useQueryClient();

  const genders = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];

  React.useEffect(() => () => toast.dismiss(), [queryClient]);

  // Explicitly type the state as a boolean
  const [invoicing, setInvoicing] = React.useState<boolean>(false);

  // Explicitly type the onNewsletterChange function
  const onInvoicingChange = (checked: boolean): void => {
    setInvoicing(checked);
  };

  const dFormik = useFormik({
    initialValues: {
      s_fname: '',
      s_lname: '',
      s_campus: 0,
      s_department: 0,
      s_regno: '',
      s_phoneno: '',
      s_sage: '',
      s_gender: '',
      s_email: '',
      s_dob: '',
    },
    validationSchema: Yup.object({
      s_fname: Yup.string()
        .required('First name is required.'),
      s_lname: Yup.string()
        .required('Last name is required.'),
      s_campus: Yup.number().required('Campus is required.'),
      s_department: Yup.number().required('Department is required.'),
      s_regno: Yup.string()
        .required('Registration number is required.'),
      s_phoneno: Yup.string()
        .matches(regPhone, 'Valid format 2507 followed by 8 digits.')
        .min(12, 'Valid format 2507 followed by 8 digits.')
        .max(12, 'Valid format 2507 followed by 8 digits.')
        .required('Phone number is required.'),
      s_sage: Yup.string()
        .required('Sage number is required.'),
      s_gender: Yup.string().required('Gender is required.'),
      s_email: Yup.string().email('Invalid email'),
      s_dob: Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      mutate({
        first_name: values.s_fname,
        last_name: values.s_lname,
        campus_id: values.s_campus,
        department_id: values.s_department,
        phone_number: values.s_phoneno,
        sage_id: values.s_sage,
        email: values.s_email,
        registration_number: values.s_regno,
        birth_date: values.s_dob,
        gender: values.s_gender,
        invoicing,
      });
      onClose();
      resetForm();
    },
  });

  const currentMonthNumber = new Date().getMonth() + 1;

  return (
    // eslint-disable-next-line max-len
    <>
      <form action="#" method="put" onSubmit={dFormik.handleSubmit} title="Student">
        <div className="grid grid-cols-5 gap-5">
          <Modal
            isOpen={isStudentCreated}
            title={isGasabo ? "Add New Seller's Information" : "Add New Student's Information"}
            onClose={onClose}
            footerContent={(
              <div className="flex justify-end">
                <Button type="reset" variant="secondary" onClick={() => dFormik.resetForm()}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="ml-4"
                  onClick={dFormik.submitForm}
                  loading={isLoading}
                >
                  save
                </Button>
              </div>
            )}
          >
            <FormLayout
              title={isGasabo ? 'Add seller information' : 'Add student information'}
              description={isGasabo ? 'Register a seller.' : 'Register a student.'}
            >
              <div className="grid grid-cols-2 gap-5">
                <Select
                  id="s_campus"
                  label={isGasabo ? 'Location' : 'Campus'}
                  placeholder={isGasabo ? 'Select location...' : 'Select campus...'}
                  className="text-black"
                  // value={selectedCampus}
                  options={campuses}
                  onChange={(option: ISelectedValue) => {
                    dFormik.setFieldValue('s_campus', option.value);
                  }}
                  onBlur={dFormik.handleBlur}
                  errorMsg={
                    dFormik.touched.s_campus
                    && dFormik.errors.s_campus
                      ? dFormik.errors.s_campus
                      : ''
                  }
                />
                <Select
                  id="s_department"
                  label={isGasabo ? 'zone' : 'department'}
                  // eslint-disable-next-line max-len
                  placeholder={isGasabo ? 'Select zone...' : 'Select Depart...'}
                  className="text-black"
                  // value={dFormik.values.s_department}
                  options={departments}
                  onChange={(option: ISelectedValue) => {
                    dFormik.setFieldValue('s_department', option.value);
                  }}
                  onBlur={dFormik.handleBlur}
                  errorMsg={
                    dFormik.touched.s_department
                    && dFormik.errors.s_department
                      ? dFormik.errors.s_department
                      : ''
                  }
                />
                <InputText
                  id="s_sage"
                  label={isGasabo ? 'rwf to pay' : 'sage number'}
                  type="text"
                  placeholder={isGasabo ? 'Money to be paid' : 'Sage number'}
                  value={dFormik.values.s_sage}
                  onBlur={dFormik.handleBlur}
                  options={students}
                  onChange={dFormik.handleChange}
                  errorMsg={
                      dFormik.touched.s_sage && dFormik.errors.s_sage
                        ? dFormik.errors.s_sage
                        : ''
                    }
                />
                <InputText
                  id="s_regno"
                  label={isGasabo ? 'Stand number' : 'Registration number'}
                  type="text"
                  placeholder={isGasabo ? 'Stand number' : 'Registration number'}
                  value={dFormik.values.s_regno}
                  onChange={dFormik.handleChange}
                  onBlur={dFormik.handleBlur}
                  errorMsg={
                      dFormik.touched.s_regno && dFormik.errors.s_regno
                        ? dFormik.errors.s_regno
                        : ''
                    }
                />
                <InputText
                  id="s_fname"
                  label="Firstname"
                  type="text"
                  placeholder="First name"
                  value={dFormik.values.s_fname}
                  onChange={dFormik.handleChange}
                  onBlur={dFormik.handleBlur}
                  errorMsg={
                      dFormik.touched.s_fname && dFormik.errors.s_fname
                        ? dFormik.errors.s_fname
                        : ''
                    }
                />
                <InputText
                  id="s_lname"
                  label="Lastname"
                  type="text"
                  placeholder="Last name"
                  value={dFormik.values.s_lname}
                  onChange={dFormik.handleChange}
                  onBlur={dFormik.handleBlur}
                  errorMsg={
                      dFormik.touched.s_lname && dFormik.errors.s_lname
                        ? dFormik.errors.s_lname
                        : ''
                    }
                />
                <Select
                  id="s_gender"
                  placeholder="Select gender..."
                  label="gender"
                  onChange={(option: ISelectedValue) => {
                    dFormik.setFieldValue('s_gender', option.value);
                  }}
                  onBlur={dFormik.handleBlur}
                  options={genders}
                  errorMsg={
                    dFormik.touched.s_gender && dFormik.errors.s_gender
                      ? dFormik.errors.s_gender
                      : ''
                  }
                />
                <InputText
                  id="s_phoneno"
                  label="Phone number"
                  type="text"
                  placeholder="2507********"
                  value={dFormik.values.s_phoneno}
                  onChange={dFormik.handleChange}
                  onBlur={dFormik.handleBlur}
                  errorMsg={
                      dFormik.touched.s_phoneno && dFormik.errors.s_phoneno
                        ? dFormik.errors.s_phoneno
                        : ''
                    }
                />
                <InputText
                  id="s_email"
                  label="Email address"
                  type="email"
                  placeholder="johndoe@email.com"
                  value={dFormik.values.s_email}
                  onChange={dFormik.handleChange}
                  onBlur={dFormik.handleBlur}
                  errorMsg={
                    dFormik.touched.s_email && dFormik.errors.s_email
                      ? dFormik.errors.s_email
                      : ''
                  }
                />
                <InputText
                  id="s_dob"
                  label="Date of birth"
                  type="date"
                  max={limitTo16Years()}
                  value={dFormik.values.s_dob}
                  onChange={dFormik.handleChange}
                  onBlur={dFormik.handleBlur}
                  errorMsg={
                    dFormik.touched.s_dob && dFormik.errors.s_dob
                      ? dFormik.errors.s_dob
                      : ''
                  }
                />
                { isGasabo && (
                  <ToogleComponent
                    id="updateInvoice"
                    checked={invoicing}
                    onChange={onInvoicingChange}
                    label={`Update ${moment(currentMonthNumber.toString()).format('MMMM')} payments ?`}
                    text=""
                  />
                )}
              </div>
            </FormLayout>
          </Modal>
        </div>
      </form>
    </>
  );
};

export default CreateStudent;
