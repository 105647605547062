/* eslint-disable func-names */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { filter, isEqual } from 'lodash';
import * as XLSX from 'xlsx';

export interface IStudentsList {
  first_name: string;
  last_name: string;
  registration_number: string;
  birth_date: string;
  email: string;
  phone_number: string;
}

export interface ITransactionData {
  hour_of_day?: number;
  week_of_month?: number;
  day_of_week?: number;
  month?: number;
  total_amount: number;
  transaction_count?: number;
}

export const studentListTemplate = [
  'Registration number',
  'Student Code',
  'First name',
  'Last name',
  'Birth date',
  'Gender',
  'Email address',
  'Phone number',
];
// data.filter((member) => member.type === 'student'
export const getOnlyStudents = (
  data: Array<Record<string, any>>,
): Array<Record<string, any>> => filter(data, (member) => member.type === 'student');

export const getActiveAccs = (
  data: Array<Record<string, any>>,
): Array<Record<string, any>> => filter(data, (acc) => acc.status === 'approved');

export const getDepartByCampus = (
  id: string | number,
  data: Array<Record<string, any>>,
): Array<Record<string, any>> => filter(data, (dept) => dept?.campus?.id === id);

export function Upload(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  evt: React.ChangeEvent<HTMLInputElement>,
  uploadExcelFile: (args: File, campusId: string, deptId: string) => void,
  campusId: string,
  deptId: string,
): void {
  let fileUpload: Record<string, any> = {};

  if (fileUpload !== null && fileUpload !== undefined) {
    fileUpload = evt.target;
  }

  if (
    fileUpload?.value?.endsWith('xlsx')
    || fileUpload?.value?.endsWith('xls')
    || fileUpload?.value?.endsWith('csv')
  ) {
    if (typeof fileUpload !== 'undefined' && fileUpload !== null) {
      return uploadExcelFile(fileUpload?.files[0], campusId, deptId);
    }
    toast.warn('This browser does not support HTML5.');
  } else {
    toast.warn('Please upload a valid Excel file.');
  }
}

export function convertJsonToXl(
  data: string[][],
  headers: string[],
  docName = 'Untitled',
): void {
  // const flattenedObj: Array<Record<string, any>> = [];

  // obj.map((o) => flattenedObj.push(flatten(o, { safe: true })));

  // const workSheet = XLSX.utils.json_to_sheet(flattenedObj);
  const workBook = XLSX.utils.book_new();
  const workSheetData = [headers, ...data];
  const workSheet = XLSX.utils.aoa_to_sheet(workSheetData);
  XLSX.utils.book_append_sheet(workBook, workSheet, 'data');

  // Generate buffer file
  XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
  // Genarate Binary string
  XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

  XLSX.writeFile(workBook, `${docName}.xlsx`);
}

export class UniqueArray extends Array {
  constructor(array: string[] | Record<string, any>[]) {
    super();
    array.forEach((a) => {
      if (!this.find((v) => isEqual(v, a))) this.push(a);
    });
  }
}

export const getTableDataForExport = (
  data: Array<Record<string, any>>,
  columns: Array<Record<string, any>>,
): Array<Record<string, any>> => data?.map((record: any) => columns.reduce(
  (recordToDownload, column) => ({
    ...recordToDownload,
    [column.Header]: record[column.accessor.toString()],
  }),
  {},
));

export const TransactionsHeaders = [
  'Academic year',
  'Student Code',
  'Amount',
  'Bank account',
  'Channel',
  'Currency',
  'Department',
  'Description',
  'External transaction ID',
  'Initial slip number',
  'Payed on',
  'Payer name',
  'Payer phone number',
  'School',
  'Service',
  'Slip number',
  'Term',
  'Transaction created on',
  'Transaction updated on',
  'Type',
];

export const StudentsHeaders = [
  'Registration Number',
  'Student ID',
  'First Name',
  'Last Name',
  'Gender',
  'Department',
  'Campus',
];

export function limitTo16Years(): string {
  const currentDate = new Date();

  // Subtract 16 years from the current date
  currentDate.setFullYear(currentDate.getFullYear() - 16);

  // Format the date as YYYY-MM-DD
  const formattedDate = `${currentDate.getFullYear()}-${`0${
    currentDate.getMonth() + 1
  }`.slice(-2)}-${`0${currentDate.getDate()}`.slice(-2)}`;

  return formattedDate;
}

export function convertMonthNumberToName(monthNumber: number): string {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames[monthNumber - 1];
}

export function convertDayNumberToName(day: number): string {
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  return days[day - 1];
}

export function labelWeek(week: number): string {
  const weeks = [
    '1st Week',
    '2nd Week',
    '3rd Week',
    '4th Week',
  ];
  return weeks[week - 1];
}

export function formatYAxisValue(value: number): string {
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}m`;
  } if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}k`;
  }
  return value.toString();
}

export const calculateDateRange = (year: number, month: number): Record<string, any> => {
  const start = new Date(year, month - 1, 1); // month is zero-indexed
  const end = new Date(year, month, 0); // last day of the selected month

  const startStr = start.toISOString().split('T')[0];
  const endStr = end.toISOString().split('T')[0];

  return { startStr, endStr };
};

export function convertHourNumberToTime(hour: number): string {
  const suffix = hour >= 12 ? 'pm' : 'am';
  const hourIn12HourFormat = hour % 12 === 0 ? 12 : hour % 12;
  const formattedHour = hourIn12HourFormat.toString().padStart(2, '0');
  return `${formattedHour}:00 ${suffix}`;
}

export const ReportHeaders = [
  'Stand Number',
  'Seller Names',
  'Phone Number',
  'Zone',
  'Month Paid For',
  'Previous Balance',
  'Expected Amount',
  'Fine',
  'Amount To Be Paid',
  'Amount Paid',
  'Balance',
  'Status',
];
