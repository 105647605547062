import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Modal, FormLayout, Button, InputText, Select, ToogleComponent,
} from '../../../../components';
import School from '../../local-helpers/requests';
import { ISelectedValue } from '../../../../components/Select';
import { regPhone } from '../../../../helpers';
import { limitTo16Years } from '../../../../helpers/functions-and-objects';
import { useCurrentUser } from '../../../../contexts/UserContext';

type Props = {
  isUpdateStudent: boolean;
  onClose: () => void;
  info: Record<string, any>;
};

const genders = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

const UpdateStudent: React.FC<Props> = ({ isUpdateStudent, onClose, info }) => {
  const { user: currentUser } = useCurrentUser();
  const schoolName = currentUser?.schools?.[0]?.name;
  const isGasabo = schoolName?.includes('Gasabo');
  const [user, setUser] = React.useState<Record<string, any>>({});
  const [type, setType] = React.useState('text');

  // Explicitly type the state as a boolean
  const [invoicing, setInvoicing] = React.useState<boolean>(false);

  // Explicitly type the onNewsletterChange function
  const onInvoicingChange = (checked: boolean): void => {
    setInvoicing(checked);
  };

  const API = new School();
  const history = useHistory();

  React.useEffect(() => {
    setUser(info);
    return () => toast.dismiss();
  }, [user, info]);

  const {
    mutate,
    isLoading,
    reset,
  } = useMutation((payload: Record<string, any>) => API.updateInfo(payload,
    currentUser.schools[0].id), {
    onSuccess() {
      onClose();
      history.push(0);
      reset();
    },
  });

  const formik = useFormik({
    initialValues: {
      first_name: user?.user?.first_name,
      last_name: user?.user?.last_name,
      phone_number: user?.user?.phone_number ?? '',
      registration_number: user?.user?.registration_number,
      sage_id: user?.sage_id ?? '',
      gender: user?.user?.gender,
      email: user?.user?.email ?? '',
      birth_date: user?.user?.birth_date,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      first_Name: Yup.string().min(3, 'Must be at least 3 characters.'),
      last_name: Yup.string().min(3, 'Must be at least 3 characters.'),
      phone_number: Yup.string()
        .notRequired()
        .nullable()
        .matches(regPhone, 'Valid format 2507 followed by 8 digits.'),
      sage_id: Yup.string().required(isGasabo ? 'The amount to be paid is required'
        : 'Sage ID is required'),
      gender: Yup.string().required('Gender is required.').nullable().notRequired(),
      email: Yup.string()
        .email('Invalid email address.').notRequired().nullable(),
      registration_number: Yup.string().required(),
    }),
    onSubmit: (values, { resetForm }) => {
      const changedValues: Partial<typeof values> = {};
      Object.keys(values).forEach((key: any) => {
        if (!isEqual(formik.initialValues[key as keyof typeof values],
          values[key as keyof typeof values])) {
          changedValues[key as keyof typeof values] = values[key as keyof typeof values];
        }
      });

      if (Object.keys(changedValues).length > 0 || invoicing) {
        mutate({
          body: {
            ...changedValues,
            invoicing,
          },
          user_id: parseInt(user?.user_id, 10),
        });

        resetForm();
      } else {
        toast.info('No changes were made');
      }
    },
  });

  const currentMonthNumber = new Date().getMonth() + 1;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        isOpen={isUpdateStudent}
        title={isGasabo ? 'Edit Seller Information' : 'Edit Student Information'}
        onClose={onClose}
        footerContent={(
          <div className="flex justify-end">
            <Button
              type="submit"
              variant="primary"
              className="ml-4"
              loading={isLoading}
              // onClick={formik.submitForm}
            >
              save changes
            </Button>
          </div>
      )}
      >
        <FormLayout
          title="Update information"
          description={isGasabo ? "Edit this seller's information"
            : "Edit this student's information."}
        >
          <InputText
            id="registration_number"
            type="text"
            label={isGasabo ? 'stand number' : 'registration number'}
            value={formik.values.registration_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.registration_number && formik.errors.registration_number
              ? formik.errors.registration_number as string : ''}
          />
          <InputText
            id="first_name"
            type="text"
            label="first name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMsg={
              formik.touched.first_name && formik.errors.first_name
                ? formik.errors.first_name as string
                : ''
            }
          />
          <InputText
            id="last_name"
            type="text"
            label="last name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMsg={
              formik.touched.last_name && formik.errors.last_name
                ? formik.errors.last_name as string
                : ''
            }
          />
          <InputText
            id="phone_number"
            type="tel"
            label="phone number"
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMsg={
              formik.touched.phone_number && formik.errors.phone_number
                ? formik.errors.phone_number as string
                : ''
            }
          />
          <InputText
            id="sage_id"
            type="text"
            label={isGasabo ? 'amount to be paid' : 'sage id'}
            value={formik.values.sage_id ?? ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMsg={
              formik.touched.sage_id && formik.errors.sage_id
                ? formik.errors.sage_id as string
                : ''
            }
          />
          <Select
            id="gender"
            placeholder={formik.values.gender}
            label="gender"
            value={formik.values.gender}
            ISelecte
            onChange={(option: ISelectedValue) => {
              formik.setFieldValue('gender', option.value);
            }}
            onBlur={formik.handleBlur}
            options={genders}
            errorMsg={
              formik.touched.gender && formik.errors.gender
                ? formik.errors.gender as string
                : ''
            }
          />
          <InputText
            id="birth_date"
            type={type}
            label="date of birth"
            value={moment(formik.values.birth_date).format('MM/DD/YYYY')}
            onChange={formik.handleChange}
            max={limitTo16Years()}
            onBlur={(e: any) => {
              formik.handleBlur(e);
              setType('text');
            }}
            onFocus={() => setType('date')}
            errorMsg={formik.touched.birth_date && formik.errors.birth_date
              ? formik.errors.birth_date as string
              : ''}
          />
          { isGasabo && (
            <ToogleComponent
              id="updateInvoice"
              checked={invoicing}
              onChange={onInvoicingChange}
              label={`Update ${moment(currentMonthNumber.toString()).format('MMMM')} payments ?`}
              text=""
            />
          )}
        </FormLayout>
      </Modal>
    </form>
  );
};

export default UpdateStudent;
