import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import {
  Modal, FormLayout, Button, InputText,
} from '../../components';
import School from './local-helpers/requests';
import { useCurrentUser } from '../../contexts/UserContext';

type Props = {
  isUpdateUser: boolean;
  onClose: () => void;
  info: Record<string, any>;
};

const UpdateUser: React.FC<Props> = ({ isUpdateUser, onClose, info }) => {
  const { user: currentUser } = useCurrentUser();
  const API = new School();
  const [user, setUser] = React.useState<Record<string, any>>({});

  React.useEffect(() => {
    setUser(info);
    return () => toast.dismiss();
  }, [user, info]);

  const {
    mutate,
    isLoading,
  // eslint-disable-next-line max-len
  } = useMutation((payload: Record<string, any>) => API.updateInfo(payload, currentUser.schools[0].id));

  const formik = useFormik({
    initialValues: {
      s_fname: user?.user?.first_name,
      s_lname: user?.user?.last_name,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      s_fname: Yup.string().min(3, 'Must be at least 3 characters.'),
      s_lname: Yup.string().min(3, 'Must be at least 3 characters.'),
    }),
    onSubmit: (values) => {
      if (!isEqual(formik.initialValues, values)) {
        mutate({
          body: {
            first_name: values.s_fname,
            last_name: values.s_lname,
            invoicing: false,
          },
          user_id: parseInt(user?.user_id, 10),
        });
      } else toast.info('No changes were made');
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        isOpen={isUpdateUser}
        title="Edit Accountant Information"
        onClose={onClose}
        footerContent={(
          <div className="flex justify-end">
            <Button type="reset" variant="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="ml-4"
              loading={isLoading}
            >
              save changes
            </Button>
          </div>
      )}
      >
        <FormLayout
          title="Update information"
          description="Edit this accountant's information."
        >
          <InputText
            id="s_fname"
            type="text"
            label="first name"
            value={formik.values.s_fname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <InputText
            id="s_lname"
            type="text"
            label="last name"
            value={formik.values.s_lname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormLayout>
      </Modal>
    </form>
  );
};

export default UpdateUser;
