import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { Role } from './helpers';
import { ROUTE } from './routes';
import Login from './pages/Login';
import FourOFour from './pages/FourOFour';
import Admin from './pages/Admin';
import { CreateAccounts } from './pages/Admin/CreateAcount';
import ProtectedRoute from './ProtectedRoute';
import SchoolAdmin from './pages/School';
import { UserProfile } from './pages/Profile/UserProfile';
import CreateAccount from './pages/School/CreateAccount';
import SchoolAdminSettings from './pages/School/Settings/Settings';
import StudentManagement from './pages/School/StudentManagement';
import UploadStudents from './pages/School/StudentManagement/UploadStudents';
import { PaymentAccounts } from './pages/Admin/PaymentAccounts';
import Users from './pages/Admin/Users';
import { Universities } from './pages/Admin/Schools';
import SchoolUsers from './pages/School/Users';
import Services from './pages/School/Services';
// import Communications from './pages/School/Communications/Communications';
import { Transactions } from './pages/Admin/Transactions';
import STransactions from './pages/School/Transactions';
import PaymentsReport from './pages/School/PaymentsReport';
import { UserProvider } from './contexts/UserContext';
import ForgotPassword from './pages/ForgotPassword';
import SetNewPassword from './pages/SetNewPassword';
import ResetPassword from './pages/ResetPassword';

const App: React.FC = () => (
  <UserProvider>
    <BrowserRouter basename="/">
      <Switch>
        <Route exact path={ROUTE.HOME} component={Login} />
        <Route path={ROUTE.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={ROUTE.SET_NEW_PASSWORD} component={SetNewPassword} />
        <ProtectedRoute
          path={ROUTE.RESET_PASSWORD}
          roles={[Role.SCHOOL_ADMIN, Role.ADMIN, Role.ACCOUNTANT]}
          component={ResetPassword}
        />
        <ProtectedRoute
          exact
          path={ROUTE.ADMIN}
          roles={[Role.ADMIN]}
          component={Admin}
        />
        <ProtectedRoute
          path={ROUTE.ADMIN_CREATE_ORG}
          roles={[Role.ADMIN]}
          component={CreateAccounts}
        />
        <ProtectedRoute
          path={ROUTE.ADMIN_TRANSACTIONS}
          roles={[Role.ADMIN]}
          component={Transactions}
        />
        <ProtectedRoute
          exact
          path={ROUTE.SCHOOL_ADMIN}
          roles={[Role.SCHOOL_ADMIN, Role.ACCOUNTANT]}
          component={SchoolAdmin}
        />
        <ProtectedRoute
          path={ROUTE.USER_PROFILE}
          roles={[Role.ACCOUNTANT, Role.ADMIN, Role.SCHOOL_ADMIN]}
          component={UserProfile}
        />
        <ProtectedRoute
          path={ROUTE.SCHOOL_ADMIN_CREATE_ACC}
          roles={[Role.SCHOOL_ADMIN]}
          component={CreateAccount}
        />
        <ProtectedRoute
          path={ROUTE.SCHOOL_ADMIN_TRANSACTIONS}
          roles={[Role.ACCOUNTANT, Role.SCHOOL_ADMIN]}
          component={STransactions}
        />
        <ProtectedRoute
          path={ROUTE.SCHOOL_ADMIN_SETTINGS}
          roles={[Role.SCHOOL_ADMIN]}
          component={SchoolAdminSettings}
        />
        <ProtectedRoute
          path={ROUTE.SCHOOL_ADMIN_STUDENTS}
          roles={[Role.SCHOOL_ADMIN, Role.ACCOUNTANT]}
          component={StudentManagement}
        />
        <ProtectedRoute
          path={ROUTE.SCHOOL_ADMIN_UPLOAD_STUDENTS}
          roles={[Role.SCHOOL_ADMIN]}
          component={UploadStudents}
        />
        <ProtectedRoute
          path={ROUTE.ADMIN_PAY_ACCOUNTS}
          roles={[Role.ADMIN]}
          component={PaymentAccounts}
        />
        <ProtectedRoute
          path={ROUTE.ADMIN_USERS}
          roles={[Role.ADMIN]}
          component={Users}
        />
        <ProtectedRoute
          path={ROUTE.ADMIN_UNIVS}
          roles={[Role.ADMIN]}
          component={Universities}
        />
        <ProtectedRoute
          path={ROUTE.SCHOOL_ADMIN_USERS}
          roles={[Role.SCHOOL_ADMIN]}
          component={SchoolUsers}
        />
        <ProtectedRoute
          path={ROUTE.SCHOOL_ADMIN_SERVICES}
          roles={[Role.SCHOOL_ADMIN, Role.ACCOUNTANT]}
          component={Services}
        />
        {/* <ProtectedRoute
          path={ROUTE.SCHOOL_ADMIN_COMMUNICATION}
          roles={[Role.SCHOOL_ADMIN, Role.ACCOUNTANT]}
          component={Communications}
        /> */}
        <ProtectedRoute
          path={ROUTE.SCHOOL_PAYMENTS_REPORT}
          roles={[Role.SCHOOL_ADMIN, Role.ACCOUNTANT]}
          component={PaymentsReport}
        />
        <Route exact path={ROUTE.NOT_FOUND} component={FourOFour} />
      </Switch>
    </BrowserRouter>
  </UserProvider>
);

export default App;
