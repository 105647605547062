import React from 'react';
import './ProgressBar.css';

interface ProgressBarProps {
  value: number; // Current progress value (0-100)
  max?: number; // Maximum value (default: 100)
  color?: string; // Bar color (default: '#4caf50')
  height?: string; // Bar height (default: '20px')
  width?: string; // Bar height (default: '100%')
  showLabel?: boolean; // Whether to show percentage text (default: true)
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  max = 100,
  color = '#4caf50',
  height = '25px',
  width = '200px',
  showLabel = true,
}) => {
  // Calculate progress as a percentage
  const progressPercentage = parseFloat(((value / max) * 100).toFixed(1));

  return (
    <div className="progress-bar-container" style={{ height, width }}>
      <div
        className="progress-bar"
        style={{
          width: `${progressPercentage}%`,
          backgroundColor: color,
          height: '100%',
        }}
      />
      {showLabel && (
        <span className="progress-bar-label">{`${!Number.isNaN(progressPercentage) ? progressPercentage : 0}%`}</span>
      )}
    </div>
  );
};

export default ProgressBar;
