/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { API, Role, APIFD } from '../../../helpers';

const api = new API();
const apifd = new APIFD();

export interface ICreateDept {
  name: string;
  campus_id: number;
}

export interface ICreateCampus {
  name: string;
  address: string;
}
export interface IStudent {
  first_name: string;
  last_name: string;
  email?: string;
  registration_number: string;
  birth_date?: string;
  campus_id: number;
  department_id: number;
  phone_number: string;
  sage_id: string;
  gender: string;
  invoicing: boolean;
}
export interface IUploadStudents {
  students: IStudent[];
}

export interface IPaymentAccount {
  name: string;
  type: string;
  bank_account_number: string;
}

export interface ISetFees {
  name: string;
  description?: string;
  amount: number;
  account_id: number;
}

export interface ICreateAccount {
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  gender: string;
  phone_number: string;
  role: 'accountant';
  school_id: number;
  departments: string[];
}

export interface IStatus {
user_id: string,
  action: string
}

class School {
  fetchFees(page: number, pageSize: number, searchString: string, schoolId: number): Promise<any> {
    return api.get(
      // eslint-disable-next-line max-len
      `/schools/${schoolId}/fees?${searchString.length > 0 ? `search=${searchString}&` : ''}page=${page + 1}&limit=${pageSize}`, (res) => res,
    );
  }

  fetchMembers(
    page: number,
    pageSize: number,
    searchString: string,
    checkedDepts: Array<string>,
    status: string,
    schoolId: number,
  ): Promise<any> {
    return api.get(
      // eslint-disable-next-line max-len
      `/schools/${schoolId}/members?${searchString.length > 0 ? `search=${searchString}&` : ''}page=${page + 1}&limit=${pageSize}${checkedDepts.length > 0 ? `&departments=${checkedDepts.toString()}` : ''}${status.length > 0 ? `&status=${status}` : ''}`,
      (res) => res,
    );
  }

  downloadMembers(
    searchString: string,
    checkedDepts: Array<string>,
    status: string,
    schoolId: number,
  ): Promise<any> {
    return api.get(
      // eslint-disable-next-line max-len
      `/schools/${schoolId}/members/download?${searchString.length > 0 ? `search=${searchString}&` : ''}${checkedDepts.length > 0 ? `&departments=${checkedDepts.toString()}` : ''}${status.length > 0 ? `&status=${status}` : ''}`,
      (res) => res,
    );
  }

  setFees(body: ISetFees, schoolId: number): Promise<any> {
    return api.post(
      `/schools/${schoolId}/fees`,
      body,
      (res) => toast.success(res.message),
    );
  }

  fetchCampuses(schoolId: number): Promise<any> {
    return api.get(
      `/schools/${schoolId}/campuses`,
      (res) => res,
    );
  }

  fetchDepartments(pageSize: number, schoolId: number): Promise<any> {
    return api.get(
      `/schools/${schoolId}/departments?page=${1}&limit=${pageSize}`,
      (res) => res,
    );
  }

  fetchTransactions(
    page: number,
    pageSize: number,
    searchString: string,
    start: string,
    end: string,
    schoolId: number,
    departmentId?: number,
  ): Promise<any> {
    return api.get(
      // eslint-disable-next-line max-len
      `/transactions?school_id=${schoolId}&${searchString.length > 0 ? `search=${searchString}&` : ''}page=${page + 1}&limit=${pageSize}${start.length > 0 && end.length > 0 ? `&start_date=${start}&end_date=${end}` : ''}${departmentId ? `&department_id=${departmentId}` : ''}`,
      (res) => res,
    );
  }

  fetchTransactionsReport(
    page: number,
    pageSize: number,
    searchString: string,
    year: string,
    month: string,
    term: string,
    status: string,
    schoolId: number,
    departmentId?: number,
  ): Promise<any> {
    const url = `/transactions/invoices?school_id=${schoolId}&${searchString.length > 0 ? `search=${searchString}&` : ''}page=${page + 1}&limit=${pageSize}${year ? `&year=${year}` : ''}${term ? `&month=${term}` : ''}${departmentId ? `&department_id=${departmentId}` : ''}${status ? `&status=${status}` : ''}`;
    return api.get(
      // eslint-disable-next-line max-len
      url,
      (res) => res,
    );
  }

  downloadTransactions = (): Promise<any> => api.get('/transactions/download', (res) => res);

  downloadTransactionsReport = (
    searchString: string,
    year: string,
    month: string,
    term: string,
    status: string,
    schoolId: number,
    departmentId?: number,
  // eslint-disable-next-line max-len
  ): Promise<any> => api.get(`/transactions/report/download?school_id=${schoolId}&${searchString.length > 0 ? `search=${searchString}&` : ''}${year && month ? `&year=${year}&month=${month}` : ''}${term ? `&term=${term}` : ''}${departmentId ? `&department_id=${departmentId}` : ''}${status ? `&status=${status}` : ''}`, (res) => res);

  createDept(body: ICreateDept, schoolId: number): Promise<any> {
    return api.post(
      `/schools/${schoolId}/departments`,
      body,
      (res) => toast.success(res.message),
    );
  }

  createCampus(body: ICreateCampus, schoolId: number): Promise<any> {
    return api.post(
      `/schools/${schoolId}/campuses`,
      body,
      (res) => res,
    );
  }

  uploadStudents(body: FormData, schoolId: number): Promise<any> {
    return apifd.post(
      `/schools/${schoolId}/students/bulk`,
      body,
      (res) => toast.success(res.message),
    );
  }

  createStudent(body: IStudent, schoolId: number): Promise<any> {
    return api.post(
      `/schools/${schoolId}/student/add`,
      body,
      (res) => toast.success(res.message),
    );
  }

  createPaymentAccount(body: IPaymentAccount, schoolId: number): Promise<any> {
    return api.post(
      `/schools/${schoolId}/accounts`,
      body,
      (res) => toast.success(res.message),
    );
  }

  fetchAccounts(schoolId: number): Promise<any> {
    return api.get(
      `/schools/${schoolId}/accounts`,
      (res) => res,
    );
  }

  createAccountantAcc = (body: ICreateAccount): Promise<any> => api.post(
    '/auth/invitation',
    body,
    (res) => toast.success(res.message),
  );

 fetchStats = (
   schoolId: number,
   filter: string | number | Date,
 ): Promise<any> => {
   const queryParams = [];

   if (schoolId) {
     queryParams.push(`school_id=${encodeURIComponent(schoolId)}`);
   }

   if (typeof filter === 'number') {
     queryParams.push(`year=${encodeURIComponent(filter)}`);
   }
   if (typeof filter === 'string' && filter.includes('day')) {
     queryParams.push(`day=${encodeURIComponent(true)}`);
   }
   if (typeof filter === 'string' && filter.includes('week')) {
     queryParams.push(`week=${encodeURIComponent(true)}`);
   }
   if (typeof filter === 'string' && filter.includes('month')) {
     queryParams.push(`month=${encodeURIComponent(true)}`);
   }
   if (typeof filter === 'string' && !Number.isNaN(Date.parse(filter))) {
     queryParams.push(`specific=${encodeURIComponent(filter.toString())}`);
   }

   const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

   return api.get(`/schools/overview/${queryString}`, (res) => res);
 };

 fetchUsers(
   page: number,
   pageSize: number,
   searchString: string,
   schoolId: number,
 ): Promise<any> {
   return api.get(
     // eslint-disable-next-line max-len
     `/schools/${schoolId}/members?type=${Role.ACCOUNTANT}&${searchString.length > 0 ? `search=${searchString}&` : ''}page=${page + 1}&limit=${pageSize}`,
     (res) => res,
   );
 }

 fetchUserDepartments(schoolId: number, userId: number): Promise<any> {
   return api.get(`/schools/${schoolId}/member/${userId}/departments`, (res) => res);
 }

 updateUserDepartments(schoolId: number, userId: number, departmentIds: number[], departmentsToRemove: number[] = []): Promise<any> {
   return api.put(`/schools/${schoolId}/member/${userId}/departments`, { departmentIds, departmentsToRemove }, (res) => toast.success(res.message));
 }

 changeStatus(payload: IStatus, schoolId: number): Promise<any> {
   // eslint-disable-next-line max-len
   return api.put(`/schools/${schoolId}/members/${payload?.user_id}/${payload?.action}`,
     {},
     (res) => toast.success(res.message));
 }

 changeFeeStatus(payload: Record<string, any>, schoolId: number): Promise<any> {
   return api.put(`/schools/${schoolId}/fees/${payload.fee_id}/${payload.action}`,
     {},
     (res) => toast.success(res.message));
 }

 updateInfo(payload: Record<string, any>, schoolId: number): Promise<any> {
   return api.put(
     `/schools/${schoolId}/members/${payload.user_id}`,
     payload.body,
     (res) => toast.success(res.message),
   );
 }

 updateService(payload: Record<string, any>, schoolId: number): Promise<any> {
   return api.put(`/schools/${schoolId}/fees/${payload.id}`,
     payload.body,
     (res) => toast.success(res.message));
 }

 deleteUser(payload: Record<string, any>, schoolId: number): Promise<any> {
   return api.delete(
     `/schools/${schoolId}/members/${payload?.user_id}`,
     (res) => toast.success(res.message),
   );
 }
}

export default School;
