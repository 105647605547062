import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import moment from 'moment';
import { TbPercentage } from 'react-icons/tb';
import { SiCashapp } from 'react-icons/si';
import { useSchoolAdminMenu } from './local-helpers/MenuItems';
import { useAccountantMenu } from '../Accountant/MenuItems';
import { Role } from '../../helpers';
import CustomContext from '../../contexts/CustomContext';
import School from './local-helpers/requests';
import { tableReducer } from '../../components/Table/TableReducer';
import {
  Layout,
  Content,
  Table,
  Button,
  Select,
  Badge,
  StatCards,
} from '../../components';
import { convertJsonToXl, ReportHeaders } from '../../helpers/functions-and-objects';
import { useCurrentUser } from '../../contexts/UserContext';

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const yearOptions = [
  { label: '2024', value: '2024' },
  { label: '2023', value: '2023' },
  { label: '2022', value: '2022' },
  { label: '2021', value: '2021' },
  // Add more years as needed
];

const monthOptions = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

const statusOptions = [
  { label: 'All', value: '' },
  { label: 'Paid', value: 'paid' },
  { label: 'Unpaid', value: 'unpaid' },
  // { label: 'Unpaid (Past Due)', value: 'unpaid (past due)' },
  { label: 'Partially Paid', value: 'partially paid' },
  { label: 'Overpaid', value: 'overpaid' },

];

const termOptions = [
  { label: '1st', value: '1' },
  { label: '2nd', value: '2' },
  { label: '3rd', value: '3' },
  { label: '4th', value: '4' },
  { label: '5th', value: '5' },
  { label: '6th', value: '6' },
  { label: '7th', value: '7' },
  { label: '8th', value: '8' },
  { label: '9th', value: '9' },
  { label: '10th', value: '10' },
  { label: '11th', value: '11' },
  { label: '12th', value: '12' },
];

interface IStats {
  id: number;
  description: string;
  numbers: string;
  icon: React.ReactNode;
  iconBgColor: string;
  progressBar?: boolean;
  progressValue?: number;
  progressMax?: number;
}

const PaymentsReport: React.FC = () => {
  const { user } = useCurrentUser();
  const schoolId = user?.schools[0]?.id;
  const role = user?.role;
  const today = new Date();
  const currentMonth = today?.toLocaleString('lt-LT', { month: 'short' });
  const currentYear = today?.toLocaleString('lt-LT', { year: 'numeric' });
  const API = new School();
  const menu = useSchoolAdminMenu();
  const accMenu = useAccountantMenu();

  const currentMonthNumber = new Date().getMonth() + 1;

  const [selectedYear, setSelectedYear] = React.useState(currentYear);
  const [selectedMonth, setSelectedMonth] = React.useState(currentMonth.toString());
  const [selectedZone, setSelectedZone] = useState(0);
  const [searchString, setSearchString] = React.useState('');
  const [selectedTerm, setSelectedTerm] = React.useState(currentMonthNumber.toString());
  const [selectedStatus, setSelectedStatus] = React.useState('');

  const [statistics, setStatistics] = React.useState<IStats[]>([]);
  const [payments, setPayments] = React.useState<any[]>([]);
  const [metaData, setMetaData] = React.useState({});

  React.useEffect(() => () => toast.dismiss(), []);

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  const { data: departmentsData, isSuccess: isDepartmentsSuccess } = useQuery(
    'departments',
    () => API.fetchDepartments(100, schoolId),
  );

  const zoneOptions = React.useMemo(() => {
    if (isDepartmentsSuccess && departmentsData) {
      const options = departmentsData.data.map(
        (department: { id: string; name: string }) => ({
          label: department.name,
          value: department.id,
        }),
      );

      if (role !== 'accountant') {
        options.unshift({ label: 'All', value: '' });
      }

      return options;
    }

    return [];
  }, [departmentsData, isDepartmentsSuccess, role]);

  const columns = React.useMemo(
    () => [
      { Header: 'Stand No', accessor: 'stand_no' },
      { Header: 'Names', accessor: 'names' },
      { Header: 'Phone number', accessor: 'phone_number' },
      { Header: 'Zone', accessor: 'department_name' },
      { Header: 'Month', accessor: 'period' },
      { Header: 'Previous', accessor: 'previous_debt' },
      { Header: 'Expected', accessor: 'expected_amount' },
      { Header: 'Fine', accessor: 'fine' },
      { Header: 'To be paid', accessor: 'amount_to_be_paid' },
      { Header: 'Paid', accessor: 'amount_paid' },
      { Header: 'Balance', accessor: 'remaining' },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => {
          let statusText = '';
          let badgeColor = '';
          switch (original?.status) {
            case 'paid':
              statusText = 'paid';
              badgeColor = 'green';
              break;
            case 'partially paid':
              statusText = 'partially paid';
              badgeColor = 'yellow';
              break;
            case 'overpaid':
              statusText = 'overpaid';
              badgeColor = 'blue';
              break;
            case 'unpaid':
            case 'unpaid (past due)':
              statusText = 'unpaid';
              badgeColor = 'red';
              break;
            default:
              statusText = original?.status;
              badgeColor = 'red';
          }

          return (
            <Badge variant={badgeColor}>
              {statusText}
            </Badge>
          );
        },
      },
    ],
    [],
  );

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const { isLoading, isSuccess } = useQuery(
    [
      'transactions',
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
      selectedYear,
      selectedMonth,
      selectedTerm,
      selectedStatus,
      selectedZone,
    ],
    () => API.fetchTransactionsReport(
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
      selectedYear,
      selectedMonth,
      selectedTerm,
      selectedStatus,
      user.schools[0].id,
      selectedZone,
    ),
    {
      onSuccess: (data) => {
        setPayments(data?.data);
        setMetaData(data?.meta);

        const totalPaid = data?.data.reduce((acc: number, curr: { amount_paid: string; }) => acc + Number(curr.amount_paid.replace(/[^0-9.-]+/g, '')), 0);
        const totalToBePaid = data?.data.reduce((acc: number, curr: { amount_to_be_paid: string; }) => acc + Number(curr.amount_to_be_paid.replace(/[^0-9.-]+/g, '')), 0);
        const totalBalance = data?.data.reduce((acc: number, curr: { remaining: string; }) => acc + Number(curr.remaining.replace(/[^0-9.-]+/g, '')), 0);
        const totalFine = data?.data.reduce((acc: number, curr: { fine: string; }) => acc + Number(curr.fine.replace(/[^0-9.-]+/g, '')), 0);
        const totalPrevious = data?.data.reduce((acc: number, curr: { previous_debt: string; }) => acc + Number(curr.previous_debt.replace(/[^0-9.-]+/g, '')), 0);
        // const totalExpected = data?.data.reduce((acc: number, curr: { expected_amount: string; }) => acc + Number(curr.expected_amount.replace(/[^0-9.-]+/g, '')), 0);

        setStatistics([
          {
            id: 5,
            description: 'Total previous debt',
            numbers: totalPrevious.toLocaleString(),
            icon: <SiCashapp color="#FF993D" className="w-5 h-5" />,
            iconBgColor: 'bg-orange-light',
          },
          {
            id: 4,
            description: 'Total fine',
            numbers: totalFine.toLocaleString(),
            icon: <SiCashapp color="#FF993D" className="w-5 h-5" />,
            iconBgColor: 'bg-orange-light',
          },
          {
            id: 1,
            description: 'Total to be paid',
            numbers: totalToBePaid.toLocaleString(),
            icon: <SiCashapp color="#FF993D" className="w-5 h-5" />,
            iconBgColor: 'bg-orange-light',
            progressBar: true,
            progressValue: totalPaid,
            progressMax: totalToBePaid,
          },
          {
            id: 2,
            description: 'Total paid',
            numbers: totalPaid.toLocaleString(),
            icon: <SiCashapp color="#00EA81" className="w-5 h-5" />,
            iconBgColor: 'bg-green-alert',
          },
          {
            id: 3,
            description: 'Total balance',
            numbers: totalBalance.toLocaleString(),
            icon: <SiCashapp color="#FF993D" className="w-5 h-5" />,
            iconBgColor: 'bg-orange-light',
          },
          // {
          //   id: 6,
          //   description: 'Total expected amount',
          //   numbers: totalExpected,
          //   icon: <TbPercentage color="#FF993D" className="w-5 h-5" />,
          //   iconBgColor: 'bg-orange-light',
          // },
        ]);
      },
    },
  );

  /* const {
    data: dtData,
    isSuccess: dtIsSuccess,
  } = useQuery(['download transactions',
    searchString,
    selectedYear,
    selectedMonth,
    selectedTerm,
    selectedStatus,
    selectedZone], () => API.downloadTransactionsReport(
    searchString,
    selectedYear,
    selectedMonth,
    selectedTerm,
    selectedStatus,
    user.schools[0].id,
    selectedZone,
  )); */

  const exportReport = (): void => {
    if (isSuccess) {
      const dataToDownload = payments?.map((t: Record<string, any>) => [
        t?.stand_no,
        t?.names,
        t?.phone_number,
        t?.department_name,
        t?.period,
        t?.previous_debt,
        t?.expected_amount,
        t?.fine,
        t?.amount_to_be_paid,
        t?.amount_paid,
        t?.remaining,
        t?.status,
      ]);
      convertJsonToXl(dataToDownload, ReportHeaders, `payments_${selectedYear}_${moment(selectedTerm).format('MMMM')}`);
    }
  };

  const isDisabled = selectedYear === ''
    || selectedMonth === '' || selectedTerm === '';
  const isDisabledMonth = selectedYear === '' || selectedMonth === '';

  return (
    <Layout
      menuItems={
        user?.role === Role.SCHOOL_ADMIN
          ? menu
          : user?.role === Role.ACCOUNTANT
            ? accMenu
            : []
      }
    >
      <Content title={`Payments Report - year: ${selectedYear || 'All'} - month:
         ${selectedTerm ? moment(selectedTerm).format('MMMM') : 'All'}`}
      >
        <CustomContext.Provider value={stateProvider}>
          <div className="w-full flex justify-between items-end flex-wrap">
            <div className="md:w-3/4 flex gap-2 flex-wrap mb-2 md:mb-0">
              <Select
                id="year-select"
                options={yearOptions}
                placeholder="Select Year"
                label="Year"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setSelectedYear(option.value as string)}
                // onBlur={() => { }}
                errorMsg=""
              />

              {/* <Select
                id="month-select"
                options={monthOptions}
                placeholder="Select Month"
                label="Month"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setSelectedMonth(option.value as string)}
                // onBlur={() => { }}
                errorMsg=""
              // isDisabled={!selectedYear}
              /> */}
              <Select
                id="month-paid-for"
                options={monthOptions}
                placeholder="Select Months paid for..."
                label="Months paid for"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setSelectedTerm(option.value as string)}
                // onBlur={() => { }}
                errorMsg=""
                isDisabled={isDisabledMonth}
              />
              <Select
                id="zone-select"
                options={zoneOptions}
                placeholder="Select Zone"
                label="Zone"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setSelectedZone(Number(option.value))}
                // onBlur={() => { }}
                errorMsg=""
              />

              <Select
                id="status-select"
                options={statusOptions}
                placeholder="Select Status"
                label="Status"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setSelectedStatus(option.value as string)}
                // onBlur={() => { }}
                errorMsg=""
                isDisabled={isDisabled}
              />
            </div>
            <div className="md:w-1/4 justify-end pb-1">
              <Button type="button" onClick={exportReport}>
                Download Report
              </Button>
            </div>
          </div>
          <h3 className="text-lg font-medium leading-6 text-black mb-1">
            SUMMARY OF PAYMENTS FOR
            {` ${moment(selectedTerm).format('MMMM')}`}
          </h3>
          <StatCards stats={statistics} />
          <Table
            columns={columns}
            data={payments || []}
            meta={metaData}
            loading={isLoading}
            countPage={
              isSuccess
                ? Math.ceil(state.totalCount / state.queryPageSize)
                : undefined
            }
            searchLabel="search payments"
            onChangeCallback={(e: any) => handleSearch(e)}
            search
          />
        </CustomContext.Provider>
      </Content>
    </Layout>
  );
};

export default PaymentsReport;
